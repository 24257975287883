<script setup lang="ts">
import type { ListItemType } from './ListTypes';
import Icon from '../icons/Icon.vue';
import { Check } from '@ui/icons/regular';

const props = withDefaults(defineProps<{
  modelValue?: ListItemType['value'][] | ListItemType['value'];
  multiple?: boolean;
  label?: string;
  icon?: string;
  value?: ListItemType | ListItemType['value'];
  flag?: boolean;
}>(), {
  multiple: false,
  label: '',
  icon: '',
  value: '',
  flag: true,
});

const checked = computed(() => {
  if (Array.isArray(props.modelValue)) {
    return props.modelValue.includes(props.value);
  }
  return props.modelValue === props.value;
});

defineSlots<{
  left: (props: { value: ListItemType['value']; checked: boolean; }) => void;
  default: (props: { value: ListItemType['value']; label: string; checked: boolean; }) => void;
  right: (props: { value: ListItemType['value']; checked: boolean; }) => void;
}>();
</script>

<template lang="pug">
li.list-item.flex-between-center.typ-14-20-400.br-6.p-8-12(:class="{checked}")
  .flex-center-center.row-8
    slot(name="left" :value="value" :checked="checked")
      Icon.icon.size-16(:name="icon")
    input.list-item-input(
      v-if="multiple"
      type="checkbox"
      :checked="checked"
      :value="modelValue"
    )
    slot(:value="value" :label="label" :checked="checked")
      | {{ $t(label) }}
  slot(name="right" :value="value" :checked="checked")
  Icon.flag.size-16(v-if="flag && !multiple && checked" :name="Check")
</template>

<style scoped lang="scss">
@import "@ui/styles/scss/checkbox-input";
@import "@ui/styles/scss/animations";

.list-item-input {
  @include checkbox-input;
}

.list-item {
  cursor: pointer;
  transition: .2s ease-in-out;
  transition-property: color, background-color;
  color: $color-gray-600;

  &.checked {
    background-color: $color-sky-400;
    color: $color-gray-900;
  }

  &:hover {
    background-color: $color-bg-grey;

    .list-item-input {
      @include checkbox-input-hover;
    }
  }
  
  &:active {
    @include click-animation(0.995);
  }
}

.flag {
  color: $color-gray-400;
}
</style>