<script setup lang="ts">
import { type BackgroundType, type Sizes, Styles } from '@ui/styles';
import InputContainer from './InputContainer.vue';
import { Times } from '@ui/icons/regular';
import IconButton from '../icons/IconButton.vue';
import Icon from '../icons/Icon.vue';
import type { Placement } from '@floating-ui/vue';

const props = withDefaults(defineProps<{
  modelValue: string;
  size?: Sizes;
  type?: string;
  label?: string;
  placeholder?: string;
  tooltip?: string;
  message?: string;
  error?: boolean;
  clean?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  iconRight?: string;
  background?: BackgroundType,
  open?: Placement | boolean;
  required?: boolean;
  autocomplete?: string;
  id?: string;
  autocapitalize?: string;
}>(), {
  modelValue: '',
  size: Styles.Sizes.Medium,
  type: 'text',
  label: '',
  placeholder: '',
  tooltip: '',
  message: '',
  error: false,
  clean: true,
  disabled: false,
  readonly: false,
  open: false,
  required: false,
});

defineSlots<{
  input: () => void;
  'left:part': () => void;
  left: () => void;
  right: () => void;
  'right:part': () => void;
  additional: () => void;
}>();

const container = ref();

defineExpose({
  container,
});
</script>

<template lang="pug">
InputContainer.ui-input.w-100p(
  ref="container"
  :class="[$slots['left:part'] && 'left', $slots['right:part'] && 'right']"
  :label="label"
  :tooltip="tooltip"
  :message="message"
  :size="size"
  :error="error"
  :disabled="disabled"
  :readonly="readonly"
  :background="background"
  :open="open"
  :required="required"
)
  slot(name="left:part")
  .input-content.flex-between-center.w-100p(@click="$emit('focus')")
    .left.flex(v-if="$slots.left")
      slot(name="left")
    slot(name="input")
      input.input.text-ellipsis(
        :id="id"
        :type="type"
        :value="modelValue"
        :disabled="disabled || readonly"
        :placeholder="$t(placeholder)"
        :autocomplete="autocomplete"
        :autocapitalize="autocapitalize"
        @input="$emit('update:modelValue', $event.target.value)"
      )
    .right.flex(v-if="$slots.right || iconRight")
      slot(name="right")
        Icon.icon-right.size-16(v-if="iconRight" :name="iconRight")
    .icon-container.flex.p-2(v-if="clean && !readonly && modelValue")
      IconButton.icon(:name="Times" :disabled="disabled" @click="$emit('update:modelValue', ''); $emit('clear')")
  slot(name="right:part")
  template(#additional)
    slot(name="additional")
</template>

<style scoped lang="scss">
@import "@ui/styles/scss/input";

.right {
  color: $color-gray-600;
  --uno: typ-16-24-400;
}

.ui-input {
  @include input-sizes;
  
  :not(.input) + .icon-container {
    border-left: 1px solid $color-gray-100;
  }

  &.disabled .right {
    color: $color-gray-200;
  }

  &.large {
    .input-content {
      --uno: row-16;
      
      :not(.input) + .icon-container {
        --uno: p-l-11;
      }
    }
  }
  
  &.medium {
    .input-content {
      --uno: row-20;
      
      :not(.input) + .icon-container {
        --uno: p-l-11;
      }
    }
  }
  
  &.small {
    .input-content {
      --uno: row-22;
      
      :not(.input) + .icon-container {
        --uno: p-l-9;
      }
    }
  }
}

.icon-right {
  color: $color-gray-400;
  flex-shrink: 0;
}

.input {
  @include input-styles;
}
</style>