export function toArray<T>(v: T | T[]): T[] {
  return Array.isArray(v) ? v : [v];
}

export function fromArray<T>(value: T | T[]) {
  return Array.isArray(value) ? value[0] : value;
}

export function isArraysEqual<T>(array1: T[], array2: T[]) {
  if (array1.length !== array2.length) return false;

  const array1Sorted = array1.slice().sort();
  const array2Sorted = array2.slice().sort();

  return array1Sorted.every((value, index) => value === array2Sorted[index]);
}
