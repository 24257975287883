<script setup lang="ts">
import ListItem from './ListItem.vue';
import type { ListItemType } from './ListTypes';
import { fromArray, toArray } from '@ui/utils/array';

const props = withDefaults(defineProps<{
  modelValue?: ListItemType['value'][] | ListItemType['value'];
  multiple?: boolean;
  items?: ListItemType[];
  flag?: boolean;
}>(), {
  multiple: false,
  items: () => [],
  flag: false,
});

defineSlots<{
  left: (props: { value: ListItemType['value']; checked: boolean; }) => void;
  default: (props: { value: ListItemType['value']; label: string; checked: boolean; }) => void;
  right: (props: { value: ListItemType['value']; checked: boolean; }) => void;
}>();

const emits = defineEmits();
function check(item: ListItemType) {
  if (props.multiple) {
    const v = toArray(props.modelValue);
    if (!v.includes(item.value)) {
      v.push(item.value);
      emits('update:modelValue', v);
    } else {
      emits('update:modelValue', v.filter(e => e !== item.value));
    }
  } else {
    const v = fromArray(props.modelValue);
    if (v !== item.value) {
      emits('update:modelValue', item.value);
    }
  }
}
</script>

<template lang="pug">
ul.flex.col-8.p-0.m-0
  slot
    ListItem(
      v-for="item of items"
      :key="item.value"
      :label="item.label"
      :value="item.value"
      :multiple="multiple"
      :modelValue="modelValue"
      :flag="flag"
      @click="check(item)"
    )
      template(#left="props")
        slot(name="left" v-bind="props")
      template(#default="props")
        slot(v-bind="props")
      template(#right="props")
        slot(name="right" v-bind="props" :item="item")
</template>
