<script setup lang="ts">
import {
  type Placement,
  autoUpdate,
  offset,
  useFloating,
  autoPlacement,
} from '@floating-ui/vue';
import { type MaybeElement, onClickOutside, syncRef } from '@vueuse/core';
import { Placements } from '@ui/utils/placements';

const props = withDefaults(defineProps<{
  show: boolean;
  reference: MaybeElement;
  ignore?: MaybeElement[];
  angle?: boolean;
  placements?: Placement[];
}>(), {
  show: false,
  reference: null,
  ignore: () => [],
  angle: true,
  placements: () => [Placements.Top, Placements.Bottom],
});

defineSlots<{
  default: () => void;
}>();

const openModel = defineModel<boolean>('show');
const placementProp = defineModel<Placement>('placement');
const { reference } = toRefs(props);

const floating = ref();
const { floatingStyles, placement } = useFloating(reference, floating, {
  open: openModel,
  whileElementsMounted: autoUpdate,
  middleware: [
    ...(props.angle ? [] : [offset({ mainAxis: 4 })]),
    autoPlacement({
      padding: 8,
      allowedPlacements: props.placements,
    }),
  ],
});

syncRef(placement, placementProp, { direction: 'ltr' });

onClickOutside(floating, event => {
  openModel.value = false;
}, { ignore: props.ignore });

const classStyle = computed(() => {
  if (!props.angle) return 'br-l-10';
  switch (placement.value) {
    case Placements.TopStart:
    case Placements.Top: return 'br-t-10';
    case Placements.BottomStart:
    case Placements.Bottom: return 'br-b-10';
    default: return 'br-l-10';
  }
})
</script>

<template lang="pug">
Transition
  .dropdown.p-5.br-r-10.scrollbar-hide(v-if="openModel" ref="floating" :style="floatingStyles" :class="classStyle")
    slot
</template>

<style scoped lang="scss">
@import "@ui/styles/scss/colors";

.dropdown {
  overflow: auto;
  max-height: calc(50dvh - 16px);
  box-shadow: 6px 20px 36px 0px #0000001A;
  background-color: $color-white;
  z-index: 100;
  transition: .3s ease-in-out;
  transition-property: transform, border;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>