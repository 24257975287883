<script setup lang="ts">
import { ref } from 'vue';
import { arrow, offset, autoPlacement, useFloating, autoUpdate, shift, type Placement } from '@floating-ui/vue';
import { useElementHover } from '@vueuse/core';
import { Placements } from '@ui/utils/placements';

const props = withDefaults(defineProps<{
  label?: string;
  disabled?: boolean;
  maxWidth?: string;
  delayEnter?: number;
  mobile?: boolean;
  placements?: Placement[];
}>(), {
  label: '',
  disabled: false,
  maxWidth: 'unset',
  delayEnter: 1500,
  mobile: true,
  placements: () => [Placements.Top, Placements.Bottom],
});


const reference = ref(null);
const floating = ref(null);
const floatingArrow = ref(null);

const isHovered = useElementHover(reference, { delayEnter: props.delayEnter, delayLeave: 100 });

const { floatingStyles, middlewareData } = useFloating(reference, floating, {
  open: isHovered,
  whileElementsMounted: autoUpdate,
  middleware: [
    offset({ mainAxis: 9 }),
    shift(),
    autoPlacement({
      padding: 8,
      allowedPlacements: props.placements,
    }),
    arrow({element: floatingArrow, padding: 12 }),
  ],
});

const bottomArrow = {
  bottom: '-5px',
  transform: 'rotate(0deg)',
};

const topArrow = {
  top: '-5px',
  transform: 'rotate(180deg)',
};
const leftArrow = {
  left: '-7px',
  transform: 'rotate(90deg)',
}

const rightArrow = {
  right: '-7px',
  transform: 'rotate(-90deg)',
}

const arrowStyles = computed<CSSStyleValue>(() => {
  const arrow = middlewareData.value.arrow || { x: 0, y: 0 };
  const placement = middlewareData.value.offset?.placement;
  if (arrow.x != null) {
    return {
      left: `${arrow.x || 0}px`,
      ...(placement === Placements.Top ? bottomArrow: topArrow),
    };
  }

  return {
    top: `${arrow.y || 0}px`,
    ...(placement === Placements.Right ? leftArrow : rightArrow),
  };
});

defineSlots<{
  default: () => void;
  tooltip: () => void;
}>()
</script>

<template lang="pug">
.ui-tooltip-container(
  ref="reference"
  @touchstart="isHovered = !isHovered"
)
  slot
  Transition
    .ui-tooltip.br-12.z-index-150(v-if="isHovered && !disabled" ref="floating" :style="floatingStyles" :class="{mobile}")
      .text(:style="{maxWidth}")
        slot(name="tooltip")
          | {{ $t(label) }}
      .arrow(ref="floatingArrow" :style="arrowStyles")
</template>

<style scoped lang="scss">
@import "@ui/styles/scss/colors";
@import "@ui/styles/scss/typography";

.ui-tooltip-container {
  display: inline-flex;
  height: fit-content;
  -webkit-tap-highlight-color: transparent;
}

.ui-tooltip {
  display: none;
  --uno: 12-14-500;
  background: $color-gray-700;
  padding: 10px 16px;
  color: $color-white;

  &.mobile {
    display: unset;
  }

  @screen md {
    display: unset;
  }
}

.arrow {
  position: absolute;
  width: 0;
  height: 0;
  transform: rotate(180deg);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid $color-gray-700;;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s linear;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transition-delay: 0.5s;
}
</style>