<script setup lang="ts">
import FieldTip from "../tips/FieldTip.vue";
import Icon from '../icons/Icon.vue';
import { TriangleExclamation } from '@ui/icons/filled';
import { Background, type BackgroundType, Styles } from '@ui/styles';
import type { Placement } from '@floating-ui/vue';

withDefaults(defineProps<{
  label?: string;
  tooltip?: string;
  message?: string;
  error?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  size?: string;
  background?: BackgroundType,
  open?: Placement | boolean;
  required?: boolean;
}>(), {
  label: '',
  tooltip: '',
  message: '',
  error: false,
  disabled: false,
  readonly: false,
  size: Styles.Sizes.Medium,
  background: Background.Gray,
  open: false,
  required: false,
});

const border = ref();

defineExpose({
  border,
});
</script>

<template lang="pug">
.ui-input.flex.col-4(:class="{disabled: disabled || readonly, error, readonly, [background]: true, [size]: true, open, [open]: true}")
  .flex-between-center.h-18.row-16(v-if="label || tooltip")
    .label.typ-12-400.flex.row-4(v-if="label") {{ $t(label) }}
      span.required(v-if="required") *
    FieldTip.p-1(v-if="tooltip" :label="tooltip")
  .input-container.flex.row-16(ref="border")
      slot
  .message.h-18.flex-unset-center.row-4.p-l-12.typ-12-400(v-if="message && !readonly")
    Icon.size-16(v-if="error" :name="TriangleExclamation")
    | {{ $t(message) }}
  slot(name="additional")
</template>

<style scoped lang="scss">
@import "@ui/styles/scss/colors";

.label,
.message {
  color: $color-gray-500;
}

.ui-input {
  .input-container {
    background-color: $color-white;
    color: $color-gray-900;
    transition: .3s ease-in-out;
    transition-property: color, background-color, outline-color, outline-width, border;
    outline: none;
    
    &:focus-within {
      outline-offset: 2px;
    }
  }

  &:not(.open) .input-container:focus-within {
    //outline: 4px solid #D4CBFB99;
  }

  &.large .input-container {
    --uno: br-16;
  }
  
  &.medium .input-container {
    --uno: br-8;
  }

  &.small .input-container {
    --uno: br-6;
  }

  &.gray {
    .input-container {
      border: 1px solid $color-bg-grey;
      background-color: $color-bg-grey;
    }
  }
  
  &.outline {
    .input-container {
      border: 1px solid $color-gray-50;
    }
  }
  
  &.open {
    &.bottom .input-container {
      --uno: br-bl-0;
    }

    &.top .input-container {
      --uno: br-tl-0;
    }
  }

  .input-container {
    background-color: $color-white;
    color: $color-gray-900;
  }

  &.error {
    .message {
      color: $color-coral-600;
    }
    
    .input-container {
      border-color: $color-coral-600;
    }
  }
  
  &:not(.disabled) {
    .input-container {
      &:hover {
        border-color: $color-gray-400;
      }
    }
  }

  &.disabled {
    .label,
    .message,
    .input-container {
      color: $color-gray-200;
    }
  }
}

.required {
  user-select: none;
}
</style>